<template>
  <div class="main-page">
    <Constructor :items="dynamicComponents.slice(0, 1)" />
    <Stories />
    <Constructor :items="dynamicComponents.slice(1)" />
    <InstagramFeed :feed="feed" />
  </div>
</template>

<script>
import {mapActions} from 'vuex';
import Constructor from '~/components/catalog/constructor/index.vue';
import pageMeta from '~/mixins/pageMeta';
import InstagramFeed from '~/components/catalog/instagramFeed/index.vue';
import Stories from '~/components/catalog/stories/index.vue';

export default {
  components: {Stories, InstagramFeed, Constructor },
  mixins: [pageMeta],
  async asyncData({ $api }) {
    const response = await Promise.allSettled([
      $api.general.getPageMeta(),
      $api.general.getPageConstructor(''),
      $api.general.getInstagramFeed(),
    ])

    const [
      { value: meta },
      { value: constructor },
    ] = response;
    return {
      pageTitle: meta?.title || '',
      pageMetaData: meta?.data || [],
      dynamicComponents: constructor?.data || [],
      feed: response[2].status !== 'rejected' ? response[2].value : [],
    };
  },
  mounted() {
    this.getConstructor();
    this.getCartItems();
  },
  methods: {
    ...mapActions('cart', ['getCartItems']),
    getConstructor() {
      return this.dynamicComponents;
    },
  },
};
</script>
