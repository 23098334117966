<template>
  <div class="stories">
    <div class="stories-container">
      <div v-for="(item, index) in items" :key="item.thumbnailUrl" class="story-item">
        <div class="story-item-image" @click.stop="handleOpenStory(index, item)">
          <div
            class="thumbnail-img"
            :style="{
              backgroundImage: `url(${item.thumbnailUrl})`,
            }"
          ></div>
        </div>
      </div>
    </div>
    <div ref="elementToDetectOutsideClick" class="stories-modal" :class="{'show' : isOpen}">
      <div class="swiper">
        <swiper ref="mySwiper" v-click-outside="handleClickOutside" :options="swiperOptions">
          <swiper-slide v-for="(item) in items" :key="item.thumbnailUrl">
            <div class="story-container">
              <div class="story-header">
                <div class="stories-strokes">
                  <div
                    v-for="(slide, idx) of item.slides"
                    :key="slide?.img || slide?.video"
                    class="stroke-item"
                    :class="{'active' : idx === 0}"
                  >
                  </div>
                </div>
                <p class="title">
                  {{ item.title }}
                </p>
              </div>
              <div class="story-body">
                <div
                  v-for="(slide, idx) of item.slides"
                  :key="slide?.image || slide?.video"
                  class="story-item"
                  :class="{ 'active' : idx === 0}"
                  :data-id="idx"
                >
                  <img
                    v-if="slide?.image"
                    :src="slide?.image"
                    :alt="slide?.image"
                  />
                  <video
                    v-else-if="slide?.video"
                    autoplay
                    loop
                    muted
                    playsinline
                  >
                    <source :src="slide?.video" />
                  </video>
                </div>
              </div>
              <div class="story-footer">
                <a :href="item.url" @click="sendAmplitudeEvent(item)">
                  <svg-icon name="chevron-up-white" width="40" height="40" />
                </a>
              </div>
            </div>
          </swiper-slide>
        </swiper>
        <div class="navigation">
          <button class="prev" @click.stop="togglePrevNextSlide(false)">
            <svg-icon name="arrow-left" width="24" height="24" />
          </button>
          <button class="next" @click.stop="togglePrevNextSlide(true)">
            <svg-icon name="arrow-right" width="24" height="24" />
          </button>
        </div>
      </div>

      <button class="close-btn" @click="handleClose()">
        <svg-icon name="close" width="24" height="24" color="white" />
      </button>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import * as amplitudeTracker from '@amplitude/analytics-browser';
import {Swiper, SwiperSlide} from 'vue-awesome-swiper';

export default {
  name: 'StoriesSlider',
  components: {Swiper, SwiperSlide},
  data() {
    return {
      isOpen: false,
      swiperOptions: {
        effect: 'cube',
        slidesPerView: 'auto',
        spaceBetween: 0,
        freeMode: true,
        sticky: true,
        mousewheel: true,
      },
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper;
    },
    ...mapGetters('stories', {
      items: 'getItems',
    }),
  },
  // mounted() {
  //   this.$refs.elementToDetectOutsideClick.addEventListener('click', this.handleClickOutside)
  // },
  // beforeUnmount() {
  //   this.$refs.elementToDetectOutsideClick.removeEventListener('click', this.handleClickOutside)
  // },
  methods: {
    handleOpenStory(index, item) {
      amplitudeTracker.track('view_stories', { stories_name: item.title });
      this.swiper.$swiper.slideToLoop(index, 1000, false);
      // TODO: hack for showing correctly slide by index
      setTimeout(() => {
        this.swiper.$swiper.slideToLoop(index, 1000, false);
      }, 0);
      this.isOpen = true;
    },
    handleClose() {
      this.isOpen = false;
    },
    handleClickOutside() {
      const isOpen = this.isOpen;
      const context = this;
      setTimeout(() => {
        if (isOpen) {
          context.handleClose();
        }
      }, 0);
    },
    currentElement() {
      const indexEl = this.swiper.$swiper.activeIndex;
      return this.swiper.$el.querySelectorAll('.story-container')[indexEl];
    },
    togglePrevNextSlide(isNext) {
      const currParentEl = this.currentElement();
      const currNodes = currParentEl.querySelectorAll('.story-item');
      const strokeNodes = currParentEl.querySelectorAll('.stroke-item');
      const currEl = currParentEl.querySelector('.story-item.active');
      const currElIndex = Number(currEl.getAttribute('data-id'));

      if (isNext && currElIndex === currNodes.length - 1) {
        return this.swiper.$swiper.slideNext();
      }
      if (!isNext && currElIndex === 0) {
        return this.swiper.$swiper.slidePrev();
      }
      currNodes.forEach((item) => item.classList.remove('active'));
      strokeNodes.forEach((item) => item.classList.remove('active'));
      currNodes[isNext ? currElIndex + 1 : currElIndex - 1].classList.add('active');
      strokeNodes[isNext ? currElIndex + 1 : currElIndex - 1].classList.add('active');
    },
    sendAmplitudeEvent(item) {
      amplitudeTracker.track('click_stories', { stories_name: item.title });
    },
  },
};
</script>

<style scoped>
.stories {
  margin-bottom: 40px;
  .stories-modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(4px);
    background-color: rgba(0, 0, 0, .3);
    transition: opacity .3s ease-in-out, visibility .3s ease-in-out;
    z-index: 1001;

    &.show {
      display: block;
    }

    .swiper {
      @media all and (min-width: 992px) {
        width: calc(var(--app-height) * 0.96 * 0.5625);
      }
      height: 96vh;
      margin: 2vh auto;
      z-index: 3;
      position: relative;

      .swiper-container-cube {
        height: 100%;
        z-index: 4;
      }

      .story-container {
        position: relative;
        height: 100%;

        .story-header {
          position: absolute;
          top: 10px;
          left: 0;
          width: 100%;
          padding: 0 4px;

          .stories-strokes {
            width: 100%;
            height: 4px;
            display: flex;
            gap: 8px;

            .stroke-item {
              width: 100%;
              height: 100%;
              border-radius: 4px;
              background-color: #696969;

              &.active {
                background-color: #ffffff;
              }
            }
          }

          .title {
            color: #ffffff;
            font-size: 20px;
          }
        }

        .story-body {
          width: 100%;
          height: 100%;
          display: flex;
          z-index: 3;

          .story-item {
            width: 0;
            border-radius: 10px;
            overflow: hidden;
            &.active {
              width: 100%;
            }
            height: 100%;

            img, video {
              width: 100%;
              height: 100%;
            }

            video {
              height: auto;
            }
          }
        }

        .story-footer {
          width: 100%;
          position: absolute;
          left: 0;
          bottom: 0;
          background-color: #000;
          color: #fff;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          font-size: 16px;
          opacity: .5;
          padding: 6px;
          text-align: center;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          z-index: 10;

          a {
            width: 100%;
            height: 100%;
            cursor: pointer;
          }
        }
      }

      @media all and (max-width: 425px) {
        width: 100%;
        height: 100%;
        margin: 0;
      }
    }

    .close-btn {
      position: absolute;
      top: 6%;
      right: 2%;
      cursor: pointer;
      z-index: 2;

      @media all and (max-width: 425px) {
        top: 4%;
        right: 4%;
        z-index: 6;

        svg {
          fill: white;
        }
      }
    }

    .navigation {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      position: absolute;
      top: 0;
      z-index: 1;

      button {
        position: absolute;
      }
      .prev {
        left: -30px;
      }
      .next {
        right: -30px;
      }

      @media all and (max-width: 425px) {
        height: calc(100% - 80px);
        z-index: 5;

        button {
          width: 50%;
          height: 100%;

          svg {
            opacity: 0;
          }
        }
      }
    }
  }

  .stories-container {
    width: 100%;
    position: relative;
    display: flex;
    overflow-y: scroll;
    gap: 12px;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    &::before {
      content: '';
      position: absolute;
      height: 62px;
      background-color: #FBF6F4;
      width: 100vw;
      top: 24px;
    }

    .story-item {
      width: 120px;
      height: fit-content;
      text-align: center;
      z-index: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      span {
        text-align: center;
      }

      .story-item-image {
        width: 100px;
        height: 100px;
        border-radius: 50px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 4px;
        border: 2px solid #BB032A;
        cursor: pointer;
        overflow: hidden;

        .thumbnail-img {
          width: 100%;
          height: 100%;
          background-color: #ffffff;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          background-origin: content-box;
          padding: 10px;
        }
      }

      .story-item-title {
        color: var(--color-basic-black);
        text-align: center;
        max-width: 120px;
      }
    }
  }
}

@media all and (min-width: 1024px) {
  .stories-container {
    justify-content: center;
  }
}
</style>
